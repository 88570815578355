import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener  } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CommonService } from '../common.service';
import { DeclarationService } from '../common/services/declaration-service';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ClientService } from '../common/services/client-service';
import { ClientSettings } from '../common/models/client-settings';

@Component({
    selector: 'app-declaration-list',
    templateUrl: './declaration-list.component.html',
    styleUrls: ['./declaration-list.component.css']
})
export class DeclarationListComponent implements OnInit, OnDestroy {
    @ViewChild(GridComponent) public grid: GridComponent;
    //@ViewChild('kendo_grid', { read: ElementRef }) kendoGrid: ElementRef;

    get includeArchived(): boolean {
        return localStorage.getItem('includeArchived') == 'true';
    }
    set includeArchived(value: boolean) {
        localStorage.setItem('includeArchived', value.toString());
    }

    pageTitle: string;
    requests: any;
    datasource: any;
    public displayedColumns: string[];
    declarationFields: any;
    clientSettings: ClientSettings;

    loading: boolean = false;
    showSpinner: boolean = false;
    showDatesOnDeclaration: boolean;

    navigationSubscription: any;

    scrHeight:any;
    scrWidth:any;

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.scrHeight = window.innerHeight;
          this.scrWidth = window.innerWidth;
          if(this.scrWidth <= 500) {
              this.displayedColumns = ['SelectBelow'];
          }
          else {
            this.displayedColumns = ['SelectBelowDes', 'From', 'To', 'Status', 'Archive'];
          }
    }

    constructor(
        private router: Router,
        private commonService: CommonService,
        private clientService: ClientService,
        private declarationService: DeclarationService) {

        this.router = router;
        this.commonService = commonService;

        this.navigationSubscription = router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.loadDeclarations();
            }
        });
        this.getScreenSize();
    }

    ngOnInit() {
        this.loadDeclarations();
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    private loadDeclarations() {
        this.loading = true;

        let getDeclarationFields = this.declarationService.getDeclarationDefinitions();
        let $getRequests = this.declarationService.getRequests(this.includeArchived);
        let $clientSettings = this.clientService.getClientSettings();

        forkJoin([getDeclarationFields, $getRequests, $clientSettings])
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    // result[0] = $getDeclarations
                    // result[1] = $getRequests
                    // result[2] = $clientSettings

                    this.declarationFields = result[0].filter((x) => { return x.fieldFormat == "Title" });

                    this.requests = result[1];
                    this.datasource = new MatTableDataSource(this.requests);
                    for (let key in this.requests) {
                        if (this.requests[key].declarationStatus == 1 || this.requests[key].declarationStatus == null) {
                            this.requests[key].statusDescription = 'New';
                        }
                        else if (this.requests[key].declarationStatus == 2) {
                            this.requests[key].statusDescription = 'In Progress';
                        }
                        else if (this.requests[key].declarationStatus == 3) {
                            this.requests[key].statusDescription = 'Submitted';
                        }
                        else if (this.requests[key].declarationStatus == 4) {
                            this.requests[key].statusDescription = 'Archived';
                        }
                    }

                    this.clientSettings = result[2];                   
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error loading the declarations list");
                    this.requests = [];
                    console.log('There was an error loading the declarations', error);
                }
            );
    }

    declarationSelected(selectedRequest) {
        if (selectedRequest) {
            this.commonService.selectedDeclaration = selectedRequest;
            this.commonService.isDeclaration = true;
            this.router.navigate(['/declarationForm']); //need to pass declaration
        }

    }

    toggleShowArchiveForms(event: MatSlideToggleChange) {
        this.includeArchived = event.checked;
        this.loadDeclarations();
    }

    canArchive(request: any) {
        return request.declarationStatus == 3;
    }

    archiveSelectedForm(event) {
        var declarationFormUniqueId = event.uniqueID;

        this.loading = true;
        this.declarationService.archiveForm(declarationFormUniqueId)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.commonService.openSnackBarMessage("Request archived succesfully");
                    this.loadDeclarations();
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error archiving the declaration");
                    console.log(error);
                }
            );
    }

    getDeclarationTitle(type: string, subType: string) {
        let decTitle = "";
        let match = this.declarationFields.filter((y) => { return y.fieldFormat == 'Title' && y.type === type && y.subType === subType });
        if (match && match.length) {
            decTitle = match[0].fieldValue;
        }

        return decTitle;
    }
}

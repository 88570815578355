import { Component, OnInit, ViewChild, ViewChildren, ElementRef, AfterViewInit, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import * as moment from 'moment';

import { CommonService } from '../common.service';
import { DeclarationService } from '../common/services/declaration-service';
import { AppConfigService } from '../app-config/app-config.service';
import { ClientService } from '../common/services/client-service';
import { ClientSettings } from '../common/models/client-settings';

@Component({
    selector: 'app-declaration-form',
    templateUrl: './declaration-form.component.html',
    styleUrls: ['./declaration-form.component.css']
})
export class DeclarationFormComponent implements OnInit, AfterViewInit {

    @ViewChild('form', { read: ElementRef }) form: ElementRef;
    // @ViewChild('percentage_input') percentageInput: ElementRef;
    // private percentageInput: ElementRef;
    // @ViewChild('percentage_input') set content(content: ElementRef) {
    //     if (content) { // initially setter gets called with undefined
    //         this.percentageInput = content;
    //     }
    // }
    @ViewChildren('percentage_input', { read: ElementRef }) perInputs: QueryList<ElementRef>;
    @ViewChildren('currency_input', { read: ElementRef }) curInputs: QueryList<ElementRef>;
    @ViewChildren('numeric_input', { read: ElementRef }) numInputs: QueryList<ElementRef>;
    @ViewChildren('postcode_input', { read: ElementRef }) posInputs: QueryList<ElementRef>;
    @ViewChildren('date_input', { read: ElementRef }) dateInputs: QueryList<ElementRef>;

    private numInput: ElementRef;

    formDefinition: any;
    declarationResponses: any;
    formTitle: string;
    formSubTitle: string;
    submittedLabel: string;
    declarationFields: any;
    employee: any;
    prefillValue: any[] = [];

    public loading: boolean;
    public selectedDeclaration: any;

    public f: FormGroup;
    public formLoaded: boolean;

    public currencySymbol: string;
    public highlightStyle: any;

    public submittedLabelText: string;
    public submittedLabelStyle: string;

    public clientSettings: ClientSettings;
    public isDebug: boolean = false;

    constructor(
        public router: Router,
        public commonService: CommonService,
        public declarationService: DeclarationService,
        public clientService: ClientService,
        public appConfig: AppConfigService,
        public snackBar: MatSnackBar,
        public formBuilder: FormBuilder,
        //private el: ElementRef
    ) {
        this.isDebug = (this.appConfig.environmentName == "Local Development");

        this.f = this.formBuilder.group({});
    }

    openSnackBar(message: string, action: string) {

        const config = new MatSnackBarConfig();
        config.panelClass = ['background-snackbar'];
        config.duration = 2000;
        config.verticalPosition = 'top';

        this.snackBar.open(message, action, config);
    }

    ngOnInit() {
        this.loading = true;
        // this.formDefinition is the raw database DeclarationsTypeMaster table values 
        // this.f is to support the reactive form binding & values
        // this.prevDeclarationFields is the saved declaration responses
        // No declaration selected == nothing to show
        this.selectedDeclaration = this.commonService.selectedDeclaration;
        if (!this.selectedDeclaration) {
            this.commonService.selectedDeclarationView = 1;
            this.commonService.isDeclaration = false;
            this.router.navigate(['/declarationList']);

            return;
        }

        console.log('selectedDeclaration', this.selectedDeclaration);

        let $declDefinitions = this.declarationService.getDeclarationDefinitions();
        let getResponses = this.declarationService.getResponses(this.selectedDeclaration.uniqueID);
        let $getClientSettings = this.clientService.getClientSettings();
        let $prefillMaps = this.declarationService.getPrefillValueMaps();
        let $employee = this.commonService.getEmployee();

        forkJoin([$declDefinitions, getResponses, $getClientSettings, $prefillMaps, $employee])
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    // result[0] == $declDefinitions
                    // result[1] == $getDeclarations
                    // result[2] == $getClientSettings
                    // result[3] == $prefillMaps
                    // result[4] == $employee

                    // Get the definition for this selected declaration
                    this.formDefinition = result[0].filter((x) => {
                        return x.type === this.selectedDeclaration.type
                            && x.subType === this.selectedDeclaration.subType;
                    })

                    if (this.formDefinition && this.formDefinition.length > 0) {
                        const fieldFormats = ["Text", "TextArea", "Percentage", "Currency", "Numeric", "PostCode", "Phone", "Date", "YesNo", "RadioButton", "Checkbox", "Confirmation"];
                        for (let i = 1; i < this.formDefinition.length; i++) {
                            if (this.formDefinition[i].fieldType == 'ValueField' && fieldFormats.includes(this.formDefinition[i].fieldFormat) && (!this.formDefinition[i].fieldTitle || this.formDefinition[i].fieldTitle == '') && this.formDefinition[i - 1].fieldFormat.toLowerCase() == 'text') {
                                this.formDefinition[i - 1].mandatory = true;
                            }
                        }
                    }

                    this.declarationResponses = result[1];
                    this.clientSettings = result[2];
                    this.mapPrefills(result[3]);
                    this.employee = result[4];

                    if (this.selectedDeclaration.submitted) {
                        this.submittedLabelText = "This form has been submitted on " + this.selectedDeclaration.submitted;
                    }
                    this.formTitle = this.getDeclarationTitle("TITLE");
                    this.formSubTitle = this.getDeclarationTitle("SUBTITLE");

                    this.currencySymbol = this.clientSettings.currency;
                    this.highlightStyle = {
                        color: this.clientSettings.highlightColor
                    }

                    this.buildForm();
                    this.prefillDisplayFields();
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error loading form data");
                    console.log('There was an error loading the declarations data', error);
                }
            );
    }

    ngAfterViewInit() {
        this.perInputs.changes.subscribe((comps: QueryList<ElementRef>) => {
            comps.forEach((element, index) => {
                const ariaLab = element.nativeElement.getAttribute('aria-label');
                const numInput = document.querySelector('kendo-numerictextbox[aria-label=' + ariaLab + '] input');
                const idval = numInput.getAttribute('id');
                numInput.setAttribute('tabindex', '0');
                const parentdiv = numInput.closest("div.k-form-field");
                const numlabel = parentdiv.querySelector("div label");
                numlabel.setAttribute('for', idval);
                element.nativeElement.removeAttribute("aria-label");
            });
        });
        this.curInputs.changes.subscribe((comps: QueryList<ElementRef>) => {
            comps.forEach((element, index) => {
                const ariaLab = element.nativeElement.getAttribute('aria-label');
                const numInput = document.querySelector('kendo-numerictextbox[aria-label=' + ariaLab + '] input');
                const idval = numInput.getAttribute('id');
                numInput.setAttribute('tabindex', '0');
                const parentdiv = numInput.closest("div.k-form-field");
                const numlabel = parentdiv.querySelector("div label");
                numlabel.setAttribute('for', idval);
                element.nativeElement.removeAttribute("aria-label");
            });
        });
        this.numInputs.changes.subscribe((comps: QueryList<ElementRef>) => {
            comps.forEach((element, index) => {
                const ariaLab = element.nativeElement.getAttribute('aria-label');
                const numInput = document.querySelector('kendo-numerictextbox[aria-label=' + ariaLab + '] input');
                const idval = numInput.getAttribute('id');
                numInput.setAttribute('tabindex', '0');
                const parentdiv = numInput.closest("div.k-form-field");
                const numlabel = parentdiv.querySelector("div label");
                numlabel.setAttribute('for', idval);
                element.nativeElement.removeAttribute("aria-label");
            });
        });
        this.posInputs.changes.subscribe((comps: QueryList<ElementRef>) => {
            comps.forEach((element, index) => {
                const ariaLab = element.nativeElement.getAttribute('aria-label');
                const numInput = document.querySelector('kendo-maskedtextbox[aria-label=' + ariaLab + '] input');
                const idval = numInput.getAttribute('id');
                numInput.setAttribute('tabindex', '0');
                const parentdiv = numInput.closest("div.k-form-field");
                const numlabel = parentdiv.querySelector("div label");
                numlabel.setAttribute('for', idval);
                element.nativeElement.removeAttribute("aria-label");
            });
        });
        this.dateInputs.changes.subscribe((comps: QueryList<ElementRef>) => {
            comps.forEach((element, index) => {
                const ariaLab = element.nativeElement.getAttribute('aria-label');
                const dateInput = document.querySelector('kendo-datepicker[aria-label=' + ariaLab + '] input');
                dateInput.removeAttribute("aria-haspopup");
                dateInput.removeAttribute("aria-expanded");
                dateInput.removeAttribute("aria-valuetext");
                const idval = dateInput.getAttribute('id');
                dateInput.setAttribute('tabindex', '0');
                const parentdiv = dateInput.closest("div.k-form-field");
                const datelabel = parentdiv.querySelector("div label");
                datelabel.setAttribute('for', idval);
                const dateSpan = document.querySelector('kendo-datepicker[aria-label=' + ariaLab + '] span.k-select');
                dateSpan.removeAttribute("aria-controls");
                element.nativeElement.removeAttribute("aria-label");
            });
        });
    }

    public isControlDisplayed(controlDefinition: any): boolean {
        if (controlDefinition.status != 'Active') {
            return false;
        }

        // If this control's display depends on the value of another control, check it here
        if (controlDefinition.dependentFieldCode &&
            controlDefinition.dependentFieldValue != null && controlDefinition.dependentFieldValue != undefined) {

            var matches = this.formDefinition.filter(df => df.fieldCode == controlDefinition.dependentFieldCode);
            if (matches && matches.length) {
                let dependentField = matches[0];

                // get the current value from reactive form
                let control = this.f.get(dependentField.fieldCode);
                if (control && control.value) {
                    // Do comparisons here -- including across different types

                    // item.dependentFieldValue is a string
                    // control.value depends on the type of control
                    switch (dependentField.fieldFormat) {
                        case 'Checkbox': {
                            return String(control.value).toUpperCase() == controlDefinition.dependentFieldValue.toUpperCase();
                        }
                        case 'Date': {
                            var dateAsString = this.getFormattedDate(control.value);
                            return dateAsString == controlDefinition.dependentFieldValue;
                        }

                        case 'Percentage':
                        case 'Numeric':
                        case 'Currency': {
                            return control.value == Number(controlDefinition.dependentFieldValue);
                        }

                        case 'CheckboxList': {
                            // cAse InsensiTive comparison
                            let display: boolean = false;
                            Object.keys(control.value).forEach(optionValue => {
                                if (optionValue.toLowerCase() == controlDefinition.dependentFieldValue.toLowerCase() && control.value[optionValue]) {
                                    display = true;
                                }
                            });
                            return display;
                        }

                        case 'Text':
                        case 'TextArea':
                        case 'YesNo':
                        case 'DropDown':
                        case 'RadioButton': {
                            return control.value.toUpperCase().trim() == controlDefinition.dependentFieldValue.toUpperCase().trim();
                        }

                        default: {
                            return control.value == controlDefinition.dependentFieldValue;
                        }
                    }
                }
            }

            // this field is dependent on the value of another field, BUT the other field's values didn't match
            // therefore, hide this field
            return false;
        }

        // No dependent field configured, so all good to show it
        return true;
    }

    public getControlLayout(item: any): string {
        if (item.fieldFormat == 'Checkbox' || item.fieldFormat == 'Confirmation') {
            return 'horizontal';
        }
        return 'vertical';
    }

    public isFormDisabled() {
        let disabled = false;

        if (this.selectedDeclaration != null &&
            (this.selectedDeclaration.declarationStatus == 3 || this.selectedDeclaration.declarationStatus == 4)) {
            disabled = true;
        }

        return disabled;
    }

    public isControlValid(item: any) {
        let control = this.f.get(item.fieldCode);
        if (control.dirty && control.errors && control.errors.required) {
            return true;
        }
        return null;
    }

    public isPhoneNumberInvalid(item: any) {
        let control = this.f.get(item.fieldCode);
        console.log('checking phone number');
        if (control.dirty && control.errors && control.errors.valid == false) {
            return true;
        }
        return null;
    }

    public getSelectOptions(fieldValue: string) {
        if (fieldValue) {
            return fieldValue.split(',').map(s => s.trim());
        }
        return [];
    }

    public getHyperlink(hyperlink: string) {
        var result = {
            url: '',
            name: ''
        };

        if (hyperlink) {
            var parts = hyperlink.split(',');
            result.url = parts[0];
            result.name = parts[1];
        }

        return result;
    }

    // Get map prefills for this given declaration type
    private mapPrefills(allPrefillMaps: any) {
        var prefill = allPrefillMaps.filter((map) => {
            return map.type === this.selectedDeclaration.type
                && map.subType === this.selectedDeclaration.subType
        })[0];

        prefill.prefillValue1.value = this.selectedDeclaration.prefillValue1;
        this.prefillValue.push(prefill.prefillValue1);
        prefill.prefillValue2.value = this.selectedDeclaration.prefillValue2;
        this.prefillValue.push(prefill.prefillValue2);
        prefill.prefillValue3.value = this.selectedDeclaration.prefillValue3;
        this.prefillValue.push(prefill.prefillValue3);
        prefill.prefillValue4.value = this.selectedDeclaration.prefillValue4;
        this.prefillValue.push(prefill.prefillValue4);
        prefill.prefillValue5.value = this.selectedDeclaration.prefillValue5;
        this.prefillValue.push(prefill.prefillValue5);
        prefill.prefillValue6.value = this.selectedDeclaration.prefillValue6;
        this.prefillValue.push(prefill.prefillValue6);
        prefill.prefillValue7.value = this.selectedDeclaration.prefillValue7;
        this.prefillValue.push(prefill.prefillValue7);
        prefill.prefillValue8.value = this.selectedDeclaration.prefillValue8;
        this.prefillValue.push(prefill.prefillValue8);

        // Always inject the delcaration "From" and "To" dates in as available pre-fill values
        this.prefillValue.push({ code: 'DATE_FROM', value: this.selectedDeclaration.fromDate });
        this.prefillValue.push({ code: 'DATE_TO', value: this.selectedDeclaration.toDate });

        // console.log('this.prefillValue are', this.prefillValue);
    }

    private prefillDisplayFields() {
        var labels = this.formDefinition.filter(f => f.fieldType == 'DisplayField');
        labels.forEach(item => {
            var result = '';
            if (item.fieldValue) {
                result = item.fieldValue;
                result = result.replace("<EmployeeName>", this.employee.employeeName);
                result = result.replace("<Username>", this.employee.username);
                result = result.replace("<EmployeeID>", this.employee.employeeID);
                result = result.replace("<ClientName>", this.commonService.selectedClient ? this.commonService.selectedClient.clientName : '');
                result = result.replace("<TODAY>", this.getFormattedDate(new Date()));
                result = result.replace("<FromDate>", new Date(this.selectedDeclaration.fromDate).toLocaleDateString("en-AU"));
                result = result.replace("<ToDate>", new Date(this.selectedDeclaration.toDate).toLocaleDateString("en-AU"));

                // console.log("looping through pre-fills in displayfield replacement");
                this.prefillValue.forEach(pfv => {
                    result = result.replace(`<${pfv.code}>`, pfv.value);
                });

                item.fieldValue = result;
            }
        });
    }

    // Builds the reactive form, which is used to bind "ValueFields" to the UI controls
    public buildForm() {
        console.log('your form definition is ', this.formDefinition);
        console.log('your current dec values are: ', this.declarationResponses);

        var valueFields = this.formDefinition.filter(f => f.fieldType == 'ValueField');

        valueFields.forEach(vf => {
            // 1. Determine the default value of this control
            let rawValue;

            // Check if form has an existing value entered ...
            var existing = this.declarationResponses.filter(v => v.fieldCode == vf.fieldCode);
            if (existing && existing.length) {
                rawValue = existing[0].fieldValue
            }
            else {
                // ... otherwise get from pre-fill value maps
                var match = this.prefillValue.filter(v => v.code == vf.fieldCode);
                if (match && match.length) {
                    rawValue = match[0].value;
                }
            }
            // console.log('Field', vf.fieldCode, 'raw value', rawValue);

            let controlValue: any;
            switch (vf.fieldFormat) {
                case 'Currency':
                case 'Numeric':
                case 'Percentage': {
                    if (rawValue) {
                        controlValue = Number(rawValue);
                    }

                    break;
                }
                case 'Checkbox':
                case 'Confirmation': {
                    controlValue = rawValue && (rawValue.toLowerCase() === 'true');
                    break;
                }
                case 'Date': {
                    controlValue = this.getDateFromString(rawValue);
                    // console.log('buildForm() - Your control value for date', controlValue);
                    break;
                }

                default: {
                    controlValue = rawValue;
                    break;
                }
            }

            //console.log('Field', vf.fieldCode, 'Format', vf.fieldFormat, 'value', controlValue);

            // 2. Create the backing FormControl in the reactive form
            let formControl: any;
            switch (vf.fieldFormat) {
                case 'CheckboxList': {
                    // Special case for checkbox lists (actually an array of values, because it supports multiple selection)
                    formControl = new FormGroup({});

                    let cbValues = this.getSelectOptions(vf.fieldValue);
                    let selectedValues = controlValue ? controlValue.split(',').map(v => v.trim()) : [];
                    cbValues.forEach(option => {
                        // console.log('Adding in option:', option);
                        var isSelected = selectedValues.filter(v => v.toUpperCase() == option.toUpperCase());
                        var optionControl = new FormControl(isSelected && isSelected.length);
                        formControl.addControl(option, optionControl);
                    })
                    break;
                }

                default: {
                    formControl = new FormControl();
                    break;
                }
            }

            // 3. Add validation to each control
            if (vf.fieldFormat == 'Confirmation') {
                // A "Confirmation" control has to be "true" (ie. ticked)
                // Ignore "mandatory" flag for this type of control
                formControl.setValidators([this.ValidateDependentFields(vf.fieldCode), this.ValidateConfirmed(vf.fieldCode)]);
            }
            else if (vf.fieldFormat == 'Phone') {
                formControl.setValidators([this.ValidateDependentFields(vf.fieldCode), this.ValidatePhoneNumber(vf.fieldCode)]);
            }
            else if (vf.fieldFormat == 'CheckboxList' && vf.mandatory) {
                formControl.setValidators([this.ValidateDependentFields(vf.fieldCode), this.ValidateMandatoryCheckboxList(vf.fieldCode)]);
            }
            else if (vf.mandatory) {
                formControl.setValidators([this.ValidateDependentFields(vf.fieldCode), this.ValidateRequired(vf.fieldCode)]);
            }
            else {
                // console.log("Default validator here at", vf.fieldCode);
                formControl.setValidators([this.ValidateDependentFields(vf.fieldCode)]);
            }


            if (this.isFormDisabled()) {
                formControl.disable();
            }
            formControl.value = controlValue;

            this.f.addControl(vf.fieldCode, formControl);
        })

        this.formLoaded = true;
    }

    public checkMe() {
        console.log('form valid', this.f.valid);
        console.log('Your reactive form is ', this.f);

        Object.keys(this.f.controls).forEach(fieldCode => {
            let control = this.f.get(fieldCode);
            console.log('field code:', fieldCode, 'status', control.status);
        });

        this.getFormResponses();
    }

    getDeclarationTitle(titleSubtitle: string) {
        var y = this.formDefinition.filter((y) => { return y.fieldCode == titleSubtitle })[0];
        if (y && y.status === "Active")
            return y.fieldValue;
        else
            return "";
    }

    cancelClicked() {
        this.commonService.selectedDeclaration = null; //clear it from the service
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;

        if (this.commonService.isArchivedNavigated)
            this.router.navigate(['/archivedDeclarationList']);
        else
            this.router.navigate(['/declarationList']);
    }

    getFormResponses() {
        var declarationResponse = [];
        Object.keys(this.f.controls).forEach(fieldCode => {
            let control = this.f.get(fieldCode);

            // Determine the control type
            let controlType: string;
            var formDef = this.formDefinition.filter(f => f.fieldCode == fieldCode);
            if (formDef && formDef.length) {
                controlType = formDef[0].fieldFormat;
            }

            // Determine the control value
            let controlValue: any;

            switch (controlType) {
                case 'CheckboxList': {
                    // For checkbox lists, return comma separated string of checked values
                    var selectedOptions = [];
                    Object.keys(control.value).forEach(optionValue => {
                        if (control.value[optionValue]) {
                            selectedOptions.push(optionValue);
                        }
                    });
                    controlValue = selectedOptions.toString();
                    break;
                }
                case 'Date': {
                    controlValue = this.getFormattedDate(control.value);
                    break;
                }
                default: {
                    controlValue = control.value;
                    break;
                }
            }

            declarationResponse.push({ key: fieldCode, value: controlValue, type: controlType });
        });

        console.log('declarationResponse: ', declarationResponse);

        return declarationResponse;
    }

    saveClicked() {
        // no need to validate, just save
        let responses = this.getFormResponses();

        this.loading = true;
        this.declarationService.saveResponses(this.selectedDeclaration.uniqueID, responses, "save")
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    // console.log(res);
                    this.openSnackBar('Declaration saved succesfully', '');

                    this.commonService.selectedDeclarationView = 1;
                    this.commonService.isDeclaration = false;
                    this.router.navigate(['/declarationList']);
                },
                (error) => {
                    console.log(error);
                    let errorMsg = (error.error) ? error.error : "There was an error saving the form";
                    this.openSnackBar(errorMsg, 'Close');
                }
            );
    }

    submitClicked() {
        let responses = this.getFormResponses();

        this.loading = true;
        this.declarationService.saveResponses(this.selectedDeclaration.uniqueID, responses, "submit")
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.openSnackBar('Declaration submitted succesfully', 'Close');

                    this.commonService.selectedDeclarationView = 1;
                    this.commonService.isDeclaration = false;
                    this.router.navigate(['/declarationList']);
                },
                (error) => {
                    console.log(error);
                    let errorMsg = (error.error) ? error.error : "There was an error submitting the form";
                    this.openSnackBar(errorMsg, 'Close');
                }
            );
    }

    private getFormattedDate(theDate: any) {
        if (theDate) {
            return moment(theDate).format("DD/MM/YYYY");
        }
        return null;
    }

    private getDateFromString(dateString: string) {
        if (dateString) {
            var mDate = moment(dateString, "DD/MM/YYYY");
            if (!mDate.isValid()) {
                // otherwise, just try parsing it any which way
                mDate = moment(dateString);
            }
        }

        if (mDate != null && mDate != undefined && mDate.isValid()) {
            return mDate.toDate();
        }
        return null;
    }

    // Custom Validators with built-in dependency control checkers
    ValidateRequired(fieldCode: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            // console.log("--- ValidateRequired, your fieldCode is", fieldCode, "its value is", control.value);

            var controlDefinition = this.formDefinition.filter(f => f.fieldCode == fieldCode);
            if (controlDefinition && controlDefinition.length) {
                if (this.isControlDisplayed(controlDefinition[0])) {
                    if (controlDefinition[0].fieldFormat == 'Text' || controlDefinition[0].fieldFormat == 'TextArea') {
                        // console.log("--- ValidateRequired, your fieldCode is", fieldCode, "its value is", control.value, 'fieldformat is', controlDefinition.fieldFormat);

                        if (!control.value || /^\s*$/.test(control.value)) {
                            return { hasValue: false };
                        }
                    }
                    else {
                        if (control.value === null || control.value === undefined || control.value === "") {
                            return { hasValue: false };
                        }
                    }
                }
            }

            // The dependent control was not displayed, so this control doesn't need a value
            return null;
        }
    }

    ValidateConfirmed(fieldCode: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            var controlDefinition = this.formDefinition.filter(f => f.fieldCode == fieldCode);
            if (controlDefinition && controlDefinition.length) {
                // console.log('ValidateConfirmed your control value is', control.value);
                // Confirmation checkbox MUST be checked for this to pass
                if (control.value != true) {
                    return { notConfirmed: true };
                }
            }

            // The dependent control was not displayed, so this control doesn't need a value
            return null;
        }
    }

    ValidatePhoneNumber(fieldCode: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            var controlDefinition = this.formDefinition.filter(f => f.fieldCode == fieldCode);
            if (controlDefinition && controlDefinition.length) {
                // console.log('ValidateConfirmed your control value is', control.value);
                // Confirmation checkbox MUST be checked for this to pass
                let phoneNumber = '';
                if (control.value) {
                    phoneNumber = control.value.trim();
                }

                if (controlDefinition[0].mandatory && phoneNumber.length == 0) {
                    return { valid: false };
                }
                else if (phoneNumber.length > 0) {
                    const regex = /^[0-9() \+]{1,40}$/;
                    if (!regex.test(phoneNumber)) {
                        return { valid: false };
                    }
                }
            }

            // The dependent control was not displayed, so this control doesn't need a value
            return null;
        }
    }

    ValidateMandatoryCheckboxList(fieldCode: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            console.log('ValidateMandatoryCheckboxList', control.value);

            // If the dependent control is NOT displayed, then this control doesn't need a value
            // and so the validation will pass
            var controlDefinition = this.formDefinition.filter(f => f.fieldCode == fieldCode);
            if (controlDefinition && controlDefinition.length) {

                for (const property in control.value) {
                    var propValue = control.value[property];
                    if (propValue == 1 || propValue == true) {
                        return null;
                    }
                }

                // Zero checkboxes were ticked, so validation failed
                return { selected: true };
            }

            // The dependent control was not displayed, so this control doesn't need a value
            return null;
        }
    }

    ValidateDependentFields(fieldCode: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            // console.log('ValidateDependentFields', fieldCode);
            // Find all other fields that are dependent on this, and update their validity?
            var dependentFields = this.formDefinition.filter(f => f.dependentFieldCode == fieldCode);
            if (dependentFields && dependentFields.length) {
                dependentFields.forEach(element => {
                    var dependentControl = this.f.get(element.fieldCode);
                    if (dependentControl) {
                        // console.log('updateValueAndValidity on dependent field', element.fieldCode);
                        dependentControl.updateValueAndValidity();
                    }
                });
            }
            return null;
        }
    }

}




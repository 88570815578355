import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'div.layout cf [id=privacy]',
    templateUrl: './privacy.component.html'
})
export class PrivacyComponent implements OnInit {


    constructor() {

    }

    ngOnInit() {

    }


}

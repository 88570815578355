import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { EmailSettings } from '../models/email-settings';
import { AppConfigService } from '../../app-config/app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailService {

    constructor(
        private httpClient: HttpClient,
        public appConfig: AppConfigService) {
    }

    getEmailSettings(): Observable<EmailSettings> {
        return this.httpClient.get<EmailSettings>(this.appConfig.apiUrl + 'api/GetEmailSettings');
    }

    saveEmailSettings(settings: EmailSettings, fileData: File): Observable<any> {
        var formData = new FormData();
        formData.append('subject', settings.subject);
        formData.append('body', settings.body);
        if (fileData) {
            formData.append('attachment1', fileData);
        }

        return this.httpClient.post(this.appConfig.apiUrl + 'api/SaveEmailSettings', formData);
    }

    sendEmailReminders(): Observable<string> {
        return this.httpClient.post<string>(this.appConfig.apiUrl + "api/sendEmailReminders", null);
    }
}

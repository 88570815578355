import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { AuthService } from "./common/services/auth.service";
import { FBTConstants } from "./common/utility/fbt-constants";
import { AppConfigService } from './app-config/app-config.service';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { ClientService } from './common/services/client-service';
import { Title } from '@angular/platform-browser';

@Injectable()
export class CommonService {

    username: string;
    userContactName: string;
    userRole: string;
    isUserAdmin: boolean;
    accessToken: string;
    logoutTime: string;
    baseUrl: string;
    requestUrl: string;
    dateFormat: string;
    admin: string = FBTConstants.role_admin;
    isArchivedNavigated: boolean = false;
    getTermsOfUseDocumentUrl: string;
    headers: Headers;
    options: RequestOptions;
    selectedDeclaration: any;
    selectedDeclarationView = 1;
    declarationDefinitions: any;
    emailSubject: string;
    emailBody: string;
    highlightColor: string;
    currencySymbol: string;
    showDeclarationDates: boolean;
    prefillMap: any[] = [];
    isDeclaration: boolean = false;
    helpText: any;
    webpageTitle: any;
    appLoginMsg: any;
    appTitle: string;

    selectedClient: any;

    constructor(
        public appConfig: AppConfigService,
        private snackBar: MatSnackBar,
        private http: Http,
        private router: Router,
        private httpClient: HttpClient,
        private titleService: Title,
        public authService: AuthService,
        private clientService: ClientService) {

        this.dateFormat = "DD/MM/YYYY";
        this.username = localStorage.getItem("azureUsername");
        
        this.authService.getUserRole().subscribe(res => {
            var response: any = res;
            this.userRole = response._body.replace(/\"/g, "");
            this.isUserAdmin = this.userRole == FBTConstants.role_admin;
        });
        this.accessToken = localStorage.getItem("azureToken");
        this.logoutTime = localStorage.getItem('logoutTime');
        this.userContactName = this.authService.getName();
        this.baseUrl = this.appConfig.apiUrl;
        this.requestUrl = this.baseUrl + 'api/logError';
        this.getTermsOfUseDocumentUrl = this.baseUrl + 'api/getTermsOfUseDocument/';
        this.headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + this.authService.getToken()
            , 'X-Frame-Options': 'SAMEORIGIN'
            , 'X-SUB-ClientID': localStorage.getItem("clientId")
        });

        this.options = new RequestOptions({ headers: this.headers });

    }

    openSnackBarMessage(message: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['background-snackbar'];
        config.duration = 2000;
        config.verticalPosition = 'top';

        this.snackBar.open(message, "Close", config);
    }

    openSnackBarError(message: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['background-snackbar'];
        config.duration = 2000;
        config.verticalPosition = 'top';

        this.snackBar.open(message, "Close", config);
    }

    //navigate
    navigate(component: string, id?: number): void {

        if (id != undefined) {
            this.router.navigate(['/' + component, id]);
        } else {
            // Force page to reload if user browses to current route
            if (this.router.url.indexOf('/' + component) >= 0) {
                location.reload();
            } else {
                this.router.navigate(['/' + component]);
            }
        }
    }

    navigate2(routeUrl: string) {
        if (this.router.url.toLowerCase() == routeUrl.toLowerCase()) {
            location.reload();
        }
        else {
            this.router.navigate([routeUrl]);
        }
    }

    azureLogin() {

    }

    getTermsOfUseDocument(): Observable<Blob> {
        var options = new RequestOptions({ headers: this.headers, responseType: ResponseContentType.Blob });
        return this.http.get(this.getTermsOfUseDocumentUrl, options)
            .map((res: Response) => <Blob>res.blob());
    }

    navigateWithStringValue(component: string, value: string): void {
        this.router.navigate(['/' + component, value]);
    }

    redirectToLogin() {
        this.signoutFrontend();
        this.navigate('login');
    }

    redirectToSignout() {
        this.signoutFrontend();
        this.navigate('signout');
    }

    isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

    signoutFrontend(): void {
        localStorage.removeItem('azureToken');
        localStorage.removeItem('azureUsername');
        localStorage.removeItem('logoutTime');
        localStorage.removeItem('azureUserContactName');
        localStorage.removeItem('id');
        localStorage.removeItem('userRole');
        localStorage.removeItem('clientId');

        this.accessToken = null;
        this.username = null;
        this.logoutTime = null;
        this.userContactName = null;
        this.userRole = null;
    }

    handleError(error: Response) {
        //log to console first
        console.log(error);
        return throwError(error);
    }

    logError(error: any): Observable<any> {

        return this.http.post(this.requestUrl, error, this.options)
            .map((res: any) => res.json())
            .do(response => console.log(response));
    }

    splitString(value: string, delimeter: string): string[] {
        if (value != null && value.length > 0)
            return value.split(delimeter);
        else
            return [];
    }

    convertArrayToDelimitedString(value: string[]): string {
        if (value != null && value.length > 0) {
            return value.toString();
        }
        else
            return "";
    }

    toSafeString(value: any): string {
        try {
            return value.toString();
        } catch (e) {
            return "";
        }
    }
    scrollToBottom() {
        window.scrollTo(0, document.body.scrollHeight);
    }
    scrollToTop() {
        window.scrollTo(0, 0);
    }

    unauthorized() {
        this.navigate("unauthorized");
    }

    isAuthorized() {
        return this.authService.isLoggedIn();
    }

    toCamel(o: any) {
        let newO: any;
        let origKey: any;
        let newKey: any;
        let value: any;
        if (o instanceof Array) {
            newO = [];
            for (origKey in o) {
                value = o[origKey];
                if (typeof value === "object") {
                    value = this.toCamel(value);
                }
                newO.push(value);
            }
        } else {
            newO = {};
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                    value = o[origKey];
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toCamel(value);
                    }
                    newO[newKey] = value;
                }
            }
        }
        return newO;
    }

    handleFailure(message: string) {
        alert(message);
    }
    handleSuccess(message: string) {
        alert(message);
    }

    getHelpText(): Observable<any> {
        return this.httpClient.get(this.appConfig.apiUrl + "api/GetHelpText");
    }

    getEmployee(): Observable<any> {
        return this.httpClient.get<any>(this.appConfig.apiUrl + "api/GetEmployee");
    }

    

    setWebPageTitle() {
        this.clientService.getClientSettings().subscribe(
            (result) => {
                this.titleService.setTitle(result.webpageTitle);
            },
            (error) => {
                console.log('common.service.setWebPageTitle() error', error);
            }
        )
    }

    getappTitle(): Observable<any> {

        var url = this.appConfig.apiUrl + "api/GetClientSettings";
        return this.http.get(url, this.options);
    }

    getAppSettings(): Observable<any> {
        var url = this.appConfig.apiUrl + "api/GetClientSettings";
        return this.http.get(url, this.options);
    }


}

import { Component, OnInit } from '@angular/core';
import { Headers, RequestOptions, Http } from '@angular/http';
import 'rxjs/Rx';
import { LoginModel } from './login';
import { LoginService } from './login.service';
import { CommonService } from '../common.service';
import { UsersService } from '../users/users.service';
import { UserModel } from './user.model';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../common/services/auth.service";

@Component({
  selector: 'div.layout cf [id=login]',
  templateUrl: './login.component.html',
  providers: [LoginService,  CommonService],
  styleUrls: ['../assets/css/style.css', '../assets/img/svgs/svgs.css']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  rememberMe: boolean;
  options: RequestOptions;
  http: Http;
  headers: Headers;
  loginService: LoginService;
  loginModel: LoginModel;
  commonService: CommonService;
  userService: UsersService;
  userModel: UserModel;
  appLoginMsg: string; 

  constructor(http: Http, loginService: LoginService, commonService: CommonService, userService: UsersService, private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) {

    this.http = http;
    this.loginModel = new LoginModel();
    this.loginModel.showSpinner = false;
    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded', 'X-Frame-Options': 'SAMEORIGIN'});
    this.options = new RequestOptions({ headers: this.headers });
    this.loginService = loginService;
    this.commonService = commonService;
    this.userService = userService;
    this.userModel = new UserModel();
    //this.testTitle = commonService.testTitle; // TODO login title change
    //clear storage when redirected to login page
    //this.commonService.signoutFrontend();

    //this.appLoginMsg = this.commonService.appLoginMsg; // TODO login title change
    //clear storage when redirected to login page
    this.setLoginTitle();
   
  }

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.authService.startAuthentication();    
    this.router.navigate([returnUrl]);
  }   

  //login
  login(): void {
  }

  setLoginTitle(){
    this.commonService.getappTitle().subscribe(res => {
      var response: any = res;
      var setupArray = JSON.parse(response._body); 
      this.appLoginMsg = setupArray.filter(x => x.setupID === "AppLoginMessage")[0].setupValue;      
    }); 
  }
 
}

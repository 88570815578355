import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
import { AuthService } from "../common/services/auth.service";
import { Http } from '@angular/http';
import { MatDialog } from '@angular/material';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../common/services/client-service';

@Component({
    selector: 'master',
    templateUrl: './master.component.html',
    styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {
    username: string;
    userContactName: string;
    headerUsernameClass: string;
    showUserIcon: boolean = false;
    showAdminPanel: boolean;
    isAdmin: boolean;
    http: Http;
    isDeclaration: Boolean = false;
    title: string;

    loading: boolean = false;

    get selectedClient(): any {
        return this.commonService.selectedClient;
    }

    set selectedClient(client: any) {
        this.commonService.selectedClient = client;
    }

    clientList: any;

    constructor(
        private router: Router,
        public commonService:CommonService,
        public clientService: ClientService,
        private dialog: MatDialog,
        private authService: AuthService) {

        this.commonService = commonService;
        this.router = router;
        this.headerUsernameClass = '';
        this.username = this.authService.getName();
        this.userContactName = commonService.userContactName;
        this.showUserIcon = true;

        this.isDeclaration = this.router.url == "declaration-form";
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
    }

    ngOnInit() {
        if (this.authService.getToken() == null || this.authService.getToken() == 'undefined') {
            this.showAdminPanel = false;
        }

        if (this.commonService.accessToken == null || this.commonService.accessToken == undefined) {
            this.showAdminPanel = false;
        }

        this.isAdmin = false;
        this.authService.isAdmin()
            .subscribe(result => {
                this.isAdmin = result;
                this.showAdminPanel = this.isAdmin;

                if (this.isAdmin) {
                    this.getAllClients();
                }
                else {
                    this.getClientForUser();
                }
            });
    }

    getClientForUser() {
        this.authService.getUserClient().subscribe(
            result => {
                this.commonService.selectedClient = result;
                localStorage.setItem('clientId', result.clientID);
            },
            (error) => {
                this.commonService.openSnackBarError("There was an error getting current client");
                console.error(error);
            }
        );
    }

    getAllClients() {
        this.clientService.getAllClients().subscribe(
            (result) => {
                this.clientList = result;

                var clientID = localStorage.getItem("clientId");
                var match = this.clientList.filter(c => c.clientID == clientID);
                if (match && match.length) {
                    this.selectedClient = match[0];
                }
                if ((this.selectedClient == null || this.selectedClient == undefined) && 
                    (this.clientList && this.clientList.length)) {
                    this.selectedClient = this.clientList[0];
                }
            },
            (error) => {
                this.commonService.openSnackBarError("There was an error loading the client list");
                console.error(error);
            }
        );
    }

    switchToClient(client: any) {
        this.selectedClient = client;
        localStorage.setItem('clientId', client.clientID);

        // If an admin is on a declaration form and changes the client, then redirect to the declaration list ..
        // otherwise just reload the current page with new clientId
        let routeUrl = this.router.url;
        if (routeUrl.toLowerCase() == "/declarationForm".toLowerCase()) {
            routeUrl = "/declarationList";
        }
        this.router.navigate([routeUrl]);
        //   this.commonService.navigate2(routeUrl);
    }

    openHelp(): void {
        var currentViewText = this.commonService.selectedDeclarationView < 10 ?
            "View0" + this.commonService.selectedDeclarationView :
            "View" + this.commonService.selectedDeclarationView;
        
            this.loading = true;
            this.commonService.getHelpText()
                .pipe(finalize(() => this.loading = false))
                .subscribe(
                    (result) => {
                        var helpHtml = result.filter((x) => {
                            return x.type === this.commonService.selectedDeclaration.type
                                && x.subType === this.commonService.selectedDeclaration.subType
                                && x.screen.trim() === currentViewText
                        });
                        let helpTextHtml: string;
                        
                        if (helpHtml && helpHtml.length) {
                            helpTextHtml = helpHtml[0].helpText1;
                        }
                        else {
                            helpTextHtml = "<span>There is no help available for this form</span>";
                        }
                        
                        const dialogRef = this.dialog.open(HelpDialogComponent,
                            {
                                data: { html: helpTextHtml }
                            }
                        );
                    },
                    (error) => {
                        console.log(error);
                        this.commonService.openSnackBarError("There was an error loading the help");
                    }
                );
    }


    logout() {
        this.authService.logOut();
        this.commonService.signoutFrontend();
        this.showUserIcon = false;
        this.showAdminPanel = false;
        this.commonService.navigate("signout");
    }

    goToUsers() {
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
        this.commonService.navigate("users");
    }

    goToAdmin() {
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
        this.commonService.navigate("admin");
    }

    goToEmailSettings() {
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
        this.commonService.navigate("emailsettings");
    }


    goToDashboard() {
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
        this.commonService.navigate("declarationList");
    }

    getTermsOfUseDocument() {
        window.open("/termsofuse", "_blank");
    }

    getPrivacyDocument() {
        window.open("/privacy", "_blank");
    }

    getCookiesInfo() {
        window.open("/cookies", "_blank");
    }
}

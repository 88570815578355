import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { LoginModel } from './login';
import { CommonService } from '../common.service';
import { UserModel } from './user.model';
import { AuthService } from "../common/services/auth.service";

@Injectable()
export class LoginService {
  loginUrl: string;
  headers: Headers;
  options: RequestOptions;
  http: Http;
  commonService: CommonService;
  requestUrl: string;
  userModel: UserModel;

  constructor(commonService: CommonService, http: Http, private authService: AuthService) {
    this.userModel = new UserModel();
    this.commonService = commonService;
    this.http = http;
    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded', 'X-Frame-Options': 'SAMEORIGIN'});
    this.options = new RequestOptions({ headers: this.headers });
    this.loginUrl = this.commonService.baseUrl + 'token';
    this.requestUrl = this.commonService.baseUrl + 'api/getUserByUsername';
  }

  doLogin(loginModel: LoginModel): Observable<any> {
    let request = new URLSearchParams();
    request.set('Username', loginModel.username);
    request.set('Password', loginModel.password);
    request.set('grant_type', 'password');
    request.set('RememberMe', loginModel.rememberMe != null ? loginModel.rememberMe.toString() : "false");

    return this.http.post(this.loginUrl, request, this.options)
      .map((res: any) => res.json())
      .catch((error: any) => {
        loginModel.showSpinner = false;
        // Error on post request.
        let json = JSON.parse(error._body);
        let message = json.error_description;
        loginModel.errorMessage = message;
        return "";
      });
  }

  //get user by username
  getUserByUsername(user: UserModel): any {
  
    let authHeaders = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + this.authService.getToken()
    });
    let authOptions = new RequestOptions({ headers: authHeaders });
    let request = JSON.stringify(user);
    return this.http.post(this.requestUrl, request, authOptions)
      .map((res: any) => <UserModel>res.json()) //todo: return ResponseModel
      .do(response => console.log(response))
      .catch((error) => {
        return this.commonService.handleError(error);
      });
  }
}

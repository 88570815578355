import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { AuthService } from "../common/services/auth.service";

@Component({
  selector: 'div.layout cf [id=register]',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {
  authService: AuthService;
  commonService: CommonService;
  constructor(commonService: CommonService, authService: AuthService) {
    this.commonService = commonService;
    this.authService = authService;
  }

  ngOnInit() {
  }

  goToLogin() {
    this.commonService.navigate('login');
  }

  signout() {
    this.authService.logOut();
    this.commonService.redirectToSignout();
  }
}

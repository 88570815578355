import { Component, OnInit } from '@angular/core';
import { LoginService } from './login/login.service';
import { CommonService } from './common.service';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
    selector: 'my-app',
    template: '<div *ngIf="loading" class="k-i-loading" style="font-size: 64px;"></div><master></master>',
    providers: [LoginService, CommonService]
})
export class AppComponent implements OnInit {
    loading: boolean;

    constructor(
        private commonService: CommonService,
        private router: Router) {

        this.loading = true;

        this.commonService.setWebPageTitle();
        this.router.events.subscribe(
            (e: RouterEvent) => {
                this.navigationInterceptor(e);
            });
    }

    ngOnInit(): void {
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true;
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            this.loading = false;
        }
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'div.layout cf [id=termsofuse]',
    templateUrl: './termsofuse.component.html'
})
export class TermsOfUseComponent implements OnInit {


    constructor() {

    }

    ngOnInit() {

    }


}

import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { GridModule } from '@progress/kendo-angular-grid';

import {
    MatListModule, MatSelectModule, MatMenuModule,
    MatDialogModule, MatSnackBarModule, MatSlideToggleModule,
    MatIconModule, MatTooltipModule, MatTableModule
} from '@angular/material';


import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MasterComponent } from './master/master.component';
import { LoginComponent } from "./login/login.component";
import { PrivacyComponent } from "./static/privacy.component";
import { CookiesComponent } from "./static/cookies.component";
import { EndUserLicenseComponent } from "./static/enduserlicense.component";
import { TermsOfUseComponent } from "./static/termsofuse.component";
import { CommonService } from "./common.service";
import { AuthCallbackComponent } from "./auth-callback/auth-callback.component";
import { UsersService } from "./users/users.service";
import { LoginService } from './login/login.service';
import { EmailService } from './common/services/email.service';

import { UsersComponent } from './users/users.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { SignoutComponent } from './signout/signout.component';
import { AppGlobals } from './globals';
import { EqualValidator } from './custom-directives/equal-validator.directive';
import { AuthenticationGuard } from './common/guards/authentication-guard';
import { AuthService } from "./common/services/auth.service";
import { DeclarationFormComponent } from './declaration-form/declaration-form.component';

import { DeclarationListComponent } from './declaration-list/declaration-list.component';

import { AdministrationComponent } from './administration/administration.component';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { AppConfigServiceFactory } from './app-config/app-config.service.factory';
import { AppConfigService } from './app-config/app-config.service';

import { ClientInterceptor } from './common/interceptors/client-interceptor';

//Uncomment when enable SSO

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'auth-callback', component: AuthCallbackComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
    { path: 'users', component: UsersComponent, runGuardsAndResolvers: 'always', canActivate: [AuthenticationGuard], data: { expectedRole: 'ADMIN' } },
    { path: 'emailsettings', component: EmailTemplateComponent, runGuardsAndResolvers: 'always', canActivate: [AuthenticationGuard], data: { expectedRole: 'ADMIN' } },
    { path: 'signout', component: SignoutComponent },
    { path: 'termsofuse', component: TermsOfUseComponent, canActivate: [AuthenticationGuard] },
    { path: 'privacy', component: PrivacyComponent, canActivate: [AuthenticationGuard] },
    { path: 'cookies', component: CookiesComponent, canActivate: [AuthenticationGuard] },
    { path: 'enduserlicense', component: EndUserLicenseComponent, canActivate: [AuthenticationGuard] },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'declarationForm', component: DeclarationFormComponent, data: { declaration: '' }, canActivate: [AuthenticationGuard] },
    { path: 'declarationList', component: DeclarationListComponent, runGuardsAndResolvers: 'always', canActivate: [AuthenticationGuard] },
    { path: 'admin', component: AdministrationComponent, runGuardsAndResolvers: 'always', canActivate: [AuthenticationGuard], data: { expectedRole: 'ADMIN' } },

    { path: '**', component: DeclarationListComponent, canActivate: [AuthenticationGuard] },
    { path: '', component: MasterComponent, canActivate: [AuthenticationGuard] }

];

//comment when enable SSO
// const appRoutes: Routes = [
//     { path: 'login', component: LoginComponent },
//     { path: 'auth-callback', component: AuthCallbackComponent },
//     { path: 'dashboard', component: DashboardComponent },
//     { path: 'users', component: UsersComponent },
//     { path: 'emailsettings', component: EmailTemplateComponent },
//     { path: 'signout', component: SignoutComponent },
//     { path: 'termsofuse', component: TermsOfUseComponent},
//     { path: 'privacy', component: PrivacyComponent},
//     { path: 'cookies', component: CookiesComponent },
//     { path: 'enduserlicense', component: EndUserLicenseComponent },
//     { path: 'unauthorized', component: UnauthorizedComponent },
//     { path: 'declarationForm', component: DeclarationFormComponent, data: { declaration: '' }},
//     { path: 'declarationList', component: DeclarationListComponent },
//     { path: 'admin', component: AdministrationComponent},

//     { path: '**', component: DeclarationListComponent},
//     { path: '', component: MasterComponent }

// ];
//comment End
@NgModule({
    imports: [BrowserModule, FormsModule, HttpModule, HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload'
        }),

        BrowserAnimationsModule,

        // Kendo Modules
        LabelModule, InputsModule, DateInputsModule, EditorModule, ToolBarModule, GridModule,

        // Material
        FlexLayoutModule,
        MatListModule, MatSelectModule, MatDialogModule,
        MatSnackBarModule, MatSlideToggleModule, MatTableModule,
        MatMenuModule, MatIconModule, MatTooltipModule
    ],
    declarations:
        [AppComponent,
            AuthCallbackComponent,
            DashboardComponent,
            MasterComponent,
            LoginComponent,
            UsersComponent,
            CookiesComponent,
            PrivacyComponent,
            TermsOfUseComponent,
            EndUserLicenseComponent,
            UnauthorizedComponent,
            EmailTemplateComponent,
            SignoutComponent,
            EqualValidator,
            DeclarationFormComponent,
            DeclarationListComponent,
            AdministrationComponent,
            HelpDialogComponent

        ],
    entryComponents: [HelpDialogComponent],
    providers: [
        AppConfigService,
        AuthService,
        CommonService,
        UsersService,
        LoginService,
        EmailService,
        AppGlobals,
        AuthenticationGuard,
        { provide: HTTP_INTERCEPTORS, useClass: ClientInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: AppConfigServiceFactory, deps: [AppConfigService], multi: true },
    ],
    bootstrap: [AppComponent]

})

export class AppModule {
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserModel } from './users.model';
import { UsersService } from './users.service';
import { CommonService } from '../common.service';
import * as $ from 'jquery';
import 'datatables.net';
import { Router, NavigationEnd } from '@angular/router';

declare var require: any;

@Component({
  selector: 'div.layout  cf [id=completed-plans]',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  usersService: UsersService;
  userModels: any[];
  userModel: any;
  clients: Array<any> = [];
  keepSorted: boolean = true;
  showSpinner: boolean = true;
  commonService: CommonService;
  showEditUser: boolean = false;

  navigationSubscription;

  constructor(
      usersService: UsersService,
      commonService: CommonService,
      router: Router
      ) {
    this.usersService = usersService;
    this.commonService = commonService;

    if (!this.commonService.isLoggedIn()) {
      this.commonService.redirectToSignout();
    }

    if (!this.commonService.isAuthorized()) {
      this.commonService.unauthorized();
    }
    this.userModel = {};
    this.commonService.scrollToTop();

    this.navigationSubscription = router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          this.ngOnInit();
        }
      });
    
  }

  ngOnInit() {

    this.showSpinner = true;
    this.usersService.getUsers().subscribe((res: any) => {
      var $ = require('jquery');

      var message = res as any[];
      this.userModels = message;

      var dataSet = this.userModels;
      var exampleId: any = $('#example');

      var options = {
        data: dataSet,
        destroy: true,
        "columns": [
          {
            "data": "username",
            render: $.fn.dataTable.render.text()
          },
          {
            "data": "role",
            render: $.fn.dataTable.render.text()
          },
          {
            "data": "isEnabled",
            "title": "Status",
            "render": function (data, type, row, meta) {
              return data == true ? "<span style='color: green'>Enabled</span>" : "<span style='color:red'>Disabled</span>";
            }
          },
          {
            "data": "id",
            "title": "Action",
            "render": function (data, type, row, meta) {
              return "<a id='btnEdit' data-element-id='" + data + "' class='cursor-hand'>Edit</a>";
            }
          }
        ]
      }
      exampleId.DataTable(options);

      this.showSpinner = false;
    });

    $(document).on('click',
      '#btnEdit',
      ($event) => {
        var event = $event as any;
        let id = event.currentTarget.dataset.elementId;
        this.editUser(id);
      });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  editUser(id: string) {
    this.showEditUser = true;
    this.showSpinner = true;
    this.usersService.getUser(id).subscribe((res) => {
      let response = res as any;
      this.userModel = response;
      this.showSpinner = false;
    });
    this.commonService.scrollToTop();
  }

  saveUser() {

    this.showSpinner = true;

    this.usersService.saveUser(this.userModel).subscribe((res) => {
      //let response = res as any;
      //if (response.isSuccess) {
      this.ngOnInit();
      this.userModel = new UserModel();
      this.commonService.scrollToBottom();
      this.showEditUser = false;
      this.showSpinner = false;
      //} else {
      //    this.commonService.handleFailure(res.message);
      //    this.showSpinner = false;
      //}
    });
  }

  cancelEdit() {
    this.userModel = new UserModel();
    this.showEditUser = false;
  }

  goToRegister() {
    this.commonService.navigate("register");
  }

  goToDashboard() {
    this.commonService.navigate("dashboard");
  }

  public log(event): void {
    console.log(event);
  }

}

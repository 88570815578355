import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../app-config/app-config.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DeclarationService {
    baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private appConfig: AppConfigService) {

        this.baseUrl = this.appConfig.apiUrl;
    }

    // Get the declaration master data for this client
    public getDeclarationDefinitions(): Observable<any> {
        return this.httpClient.get(this.appConfig.apiUrl + "api/GetAllDeclarationTypes");
    }

    public getPrefillValueMaps(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.appConfig.apiUrl + "api/GetPrefillValueMaps")
            .map(result => {
                var prefillMap = [];
                result.forEach(map => {
                    var valueMap = {
                        type: map.type,
                        subType: map.subType,
                        prefillValue1: { code: map.prefillValue1, value: "" },
                        prefillValue2: { code: map.prefillValue2, value: "" },
                        prefillValue3: { code: map.prefillValue3, value: "" },
                        prefillValue4: { code: map.prefillValue4, value: "" },
                        prefillValue5: { code: map.prefillValue5, value: "" },
                        prefillValue6: { code: map.prefillValue6, value: "" },
                        prefillValue7: { code: map.prefillValue7, value: "" },
                        prefillValue8: { code: map.prefillValue8, value: "" }
                    }
                    prefillMap.push(valueMap);
                });
                return prefillMap;
            });
    }

    public getResponses(uniqueId: string): Observable<any> {
        return this.httpClient.get(this.appConfig.apiUrl + `api/GetDeclarationResponses?uniqueID=${uniqueId}`);
    }

    public saveResponses(uniqueId: string, declarationResponse: any, action: string): Observable<any> {
        return this.httpClient.post<any[]>(this.appConfig.apiUrl + `api/SubmitDeclaration?uniqueID=${uniqueId}&save=${action}`, declarationResponse);
    }

    // Get requests for the current user
    public getRequests(includeArchived: boolean): Observable<any> {
        return this.httpClient.get(this.appConfig.apiUrl + `api/GetRequests?includeArchived=${includeArchived}`);
    }

    public archiveForm(declarationFormUniqueId: number): Observable<any> {
        return this.httpClient.post(this.appConfig.apiUrl + "api/ArchiveForm?uniqueId=" + declarationFormUniqueId, null);
    }
    
}
﻿////
//// ===== File globals.ts    
////
//'use strict';

//export const showAdminPanel: boolean = false;


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/startWith';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class AppGlobals {
  // use this property for property binding
  public showUserIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setLoginStatus(isAdmin) {
    this.showUserIcon.next(isAdmin);
  }
}
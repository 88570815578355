import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'div.layout cf [id=cookies]',
    templateUrl: './cookies.component.html'
})
export class CookiesComponent implements OnInit {
   

    constructor() {
      
    }

    ngOnInit() {

    }

  
}

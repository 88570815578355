import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { CommonService } from '../common.service';
import { UserModel } from './users.model';
import { AuthService } from "../common/services/auth.service";
import { AppConfigService } from '../app-config/app-config.service';

@Injectable()
export class UsersService {
  getRequestUrl: string;
  postRequestUrl: string;
  http: Http;
  
  commonService: CommonService;
  userModel: UserModel;

  get options(): RequestOptions {
      let headers = new Headers({
        'Content-Type': 'application/json'
        , 'Authorization': 'Bearer ' + this.authService.getToken()
        , 'X-Frame-Options': 'SAMEORIGIN'
        , 'X-SUB-ClientID' : localStorage.getItem("clientId")
      });
    return new RequestOptions({ headers: headers });
  }

  constructor(
    public appConfig: AppConfigService,
      http: Http, commonService: CommonService, private authService: AuthService) {
    this.http = http;
    this.commonService = commonService;
    this.userModel = new UserModel();
    
    this.getRequestUrl = this.commonService.baseUrl + 'api/getUsers';
    this.postRequestUrl = this.commonService.baseUrl + 'api/saveUser';
  }

  saveUser(model: UserModel): Observable<any> {
    var request = JSON.stringify(model);

    return this.http.post(this.appConfig.apiUrl + "api/SaveUser", request, this.options)
      .map((res: any) => res.json())
      //.do(response => console.log(response))
      .catch(this.commonService.handleError.bind(this.commonService).bind(this.commonService));
  }

  getUsers() {
    return this.http.get(this.appConfig.apiUrl + "api/GetEmployees", this.options)
      .map((res: any) => <UserModel>res.json())
      .do(response => console.log(response))
      .catch((error) => {
        return this.commonService.handleError(error);
      });
  }

  getUser(userId: string) {
    return this.http.get(this.appConfig.apiUrl + "api/GetEmployee?id=" + userId, this.options) //todo: do the same as above but for get single user
      .map((res: any) => <UserModel>res.json())
      .do(response => console.log(response))
      .catch((error) => {
        return this.commonService.handleError(error);
      });
  }

  checkIsAdmin(): any {
    return this.http.get(this.commonService.baseUrl + 'api/checkIsAdmin/', this.options)
      .map((res: any) => res.json())
      .do(response => console.log(response))
      .catch((error) => {
        return this.commonService.handleError(error);
      });
  }

  checkIsUserEnabled(): any {
    return this.http.get(this.commonService.baseUrl + 'api/checkIsUserEnabled/', this.options)
      .map((res: any) => res.json());
  }
}

import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, OidcMetadata, OidcClient } from 'oidc-client';
import * as jwt_decode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FBTConstants } from "../utility/fbt-constants";
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AppConfigService } from '../../app-config/app-config.service';


@Injectable()
export class AuthService {
    private manager: UserManager = new UserManager(this.getClientSettings());
    private metaData: OidcMetadata;
    private user: DPassUser = null;
    private email: string;
    private accesToken: string;
    private helper: JwtHelperService;
    options: RequestOptions;
    private httpHeaders: any;
    headers: Headers;

    constructor(
        public http: Http,
        public httpClient: HttpClient,
        public appConfig: AppConfigService) {
        this.helper = new JwtHelperService();
        this.headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
            , 'Authorization': 'Bearer ' + this.getToken()
            , 'X-Frame-Options': 'SAMEORIGIN'
        });
        this.options = new RequestOptions({ headers: this.headers });

        // New way of doing this
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + this.getToken()
            })
        }
    }

    getUserRole(): Observable<any> {
        return this.http.get(`${this.appConfig.apiUrl}api/getUserRole`, this.options);
    }

    getUserClient(): Observable<any> {
        return this.httpClient.get(`${this.appConfig.apiUrl}api/getUserClient`, this.httpHeaders);
    }

    isAdmin(): Observable<any> {
        return this.getUserRole().map(result => {
            let role = result._body.replace(/\"/g, "");
            return (role == FBTConstants.role_admin);
        });
    }

    isLoggedIn(): boolean {
        return !this.isTokenExpired();
    }

    getUser(token: string): DPassUser {
        return jwt_decode(token);
    }


    startAuthentication(): Promise<void> {
        return this.manager.signinRedirect();
    }

    getToken(): string {
        try {
            return localStorage.getItem("azureToken");
        }
        catch (exception) {
            return null
        }
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(token?: string): boolean {
        try {
            if (!token)
                token = this.getToken();

            if (!token) return true;
            return this.helper.isTokenExpired(token);
        }
        catch (exception) {
            return true;
        }
    }

    logOut() {
        this.manager.createSignoutRequest();
        location.href = this.appConfig.postLogoutRedirectUri;
    }

    isTokenTimeLapsed() {
        try {
            return (Date.now() / 1000) >= (+localStorage.getItem("exp"));
        }
        catch {
            return true;
        }
    }

    getUsername() {
        return localStorage.getItem("azureUsername");
    }

    getName() {
        return localStorage.getItem("azureUserContactName");
    }

    async completeAuthentication(token: string): Promise<void> {
        const decoded = jwt_decode(token);
        console.log(decoded);
        localStorage.setItem("azureUsername", decoded.upn);
        localStorage.setItem("azureUserContactName", decoded.name);
        localStorage.setItem("azureToken", token);
        localStorage.setItem("exp", decoded.exp);
        localStorage.setItem("iat", decoded.iat);

        if (!localStorage.getItem("azureUsername")) {
            localStorage.setItem("azureUsername", decoded.email);
        }

        if (!localStorage.getItem("azureUserContactName")) {
            localStorage.setItem("azureUserContactName", decoded.upn);
        }


        location.href = "/";
    }

    getClientSettings(): any {
        return {
            authority: this.appConfig.authority,
            client_id: this.appConfig.clientId,
            redirect_uri: this.appConfig.redirectUri,
            post_logout_redirect_uri: this.appConfig.postLogoutRedirectUri,
            response_type: this.appConfig.responseType,
            resource: this.appConfig.resource,
            scope: this.appConfig.scope,
            silent_redirect_uri: this.appConfig.silentRedirectUri,
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 4,
            filterProtocolClaims: true,
            loadUserInfo: true,
            allowAccessTokensViaBrowser: true,
            metadata: {
                issuer: this.appConfig.issuer,
                authorization_endpoint: this.appConfig.authorizationEndpoint,
                userinfo_endpoint: this.appConfig.userinfoEndpoint,
                end_session_endpoint: this.appConfig.endSessionEndpoint,
                jwks_uri: this.appConfig.jwksUri
            },
            signingKeys: this.appConfig.signingKeys
        };
    }

}


@Injectable()
export class DPassUser {
    aud: string;
    auth_time: string;
    exp: number;
    iat: number;
    iss: string;
    nonce: string;
    pwd_exp: string;
    pwd_url: string;
    sid: string;
    sub: string;
    unique_name: string;
    upn: string;
}

export class UserModel {
  id: string;
  userId: string;
  contactName: string;
  role: string;
  username: string;
  email: string;
  phoneNumber: string;
  isEnabled: boolean;
  clients: any[];
}

import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { UserModel } from '../login/user.model';
import { LoginService } from '../login/login.service';
import { AuthService } from "../common/services/auth.service";

@Component({
  selector: 'div.layout cf [id=dashboard]',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  commonService: CommonService;
  username: string;
  userContactName: string;
  loginService: LoginService;
  reports: any;
  reportsAvailable: boolean = true;

  constructor(commonService: CommonService, loginService: LoginService, private authService: AuthService) {
    this.commonService = commonService;
    this.loginService = loginService;
    //this.authService.setUserLocalStorage();
  }

  ngOnInit() {
      this.userContactName = localStorage.getItem('azureUserContactName');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../app-config/app-config.service';
import { Observable } from 'rxjs';
import { ClientSettings } from '../models/client-settings';

@Injectable({
    providedIn: 'root'
})

export class ClientService {
    baseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private appConfig: AppConfigService) {

        this.baseUrl = this.appConfig.apiUrl;
    }

    public getAllClients(): Observable<any> {
        return this.httpClient.get<any>(this.appConfig.apiUrl + "api/GetAdminClients");
    }

    public getClientSettings(): Observable<ClientSettings> {
        return this.httpClient.get<ClientSettings>(this.appConfig.apiUrl + "api/GetClientSettings");
    }

    public saveClientSettings(settings: ClientSettings): Observable<any> {
        return this.httpClient.post(this.appConfig.apiUrl + "api/SaveSettings", settings);
    }

    public getSubmittedData(): Observable<any> {
        return this.httpClient.post(this.appConfig.apiUrl + "api/GetSubmittedData", null);
    }
    
}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EmailService } from '../common/services/email.service';
import { finalize } from 'rxjs/operators';
import { EmailSettings } from '../common/models/email-settings';

@Component({
    selector: 'div.layout cf [id=register]',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit, OnDestroy {
    route: ActivatedRoute;
    emailSubj: string;
    emailBody: string;
    selectedFile: File = null;

    loading: boolean = false;
    emailSettings: EmailSettings = new EmailSettings();
    
    private navigationSubscription: any;
    

    constructor(
        private emailService: EmailService,
        private commonService: CommonService,
        private router: Router) {

        this.navigationSubscription = router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.loadEmailSettings();
            }
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    loadEmailSettings() {
        this.loading = true;
        this.emailService.getEmailSettings()
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.emailSettings = result;
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error loading the email settings");
                    console.log(error);
                }
            )
    }

    saveEmailSettings() {
        this.loading = true;
        this.emailService.saveEmailSettings(this.emailSettings, this.selectedFile)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.commonService.openSnackBarError("Email settings saved OK");
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error loading the email settings");
                    console.log(error);
                }
            )
    }

    cancelEdit() {
        this.commonService.selectedDeclarationView = 1;
        this.commonService.isDeclaration = false;
        this.commonService.navigate("/admin");
    }

    onFileSelected(event) {
        this.selectedFile = <File>event.target.files[0];
    }


    sendEmailReminders() {
        this.loading = true;
        this.emailService.sendEmailReminders()
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.commonService.openSnackBarMessage(result);
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error sending the email reminders");
                    console.log('email-template.sendEmailReminders() error', error);
                }
            );
    }
}

import { Http, Response, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { UsersService } from '../../users/users.service';
import { CommonService } from '../../common.service';

import * as $ from 'jquery';
import { AuthService } from "../services/auth.service";
import { Observable } from "rxjs/Observable";

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild, OnInit {
  usersService: UsersService;
  router: Router;
  accessToken: string;
  headers: Headers;
  isUserAdmin: boolean;
  commonService: CommonService;
  constructor(
    router: Router,
    private authService: AuthService,
    usersService: UsersService,
    commonService: CommonService
  ) {
    this.usersService = usersService;
    this.router = router;
    this.commonService = commonService;
    // this.checkIfAdmin();
  }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> { //boolean | Observable<boolean> | Promise<boolean> {

    if (this.authService.isTokenExpired() || this.authService.isTokenTimeLapsed()) {
      this.router.navigate(['/login']);
      return false;
    }

    let isEnabled = await this.checkIsUserEnabled();
    if (!isEnabled) {
      this.router.navigate(['/unauthorized']);
      return false;
    }

    if (route.data.expectedRole == this.commonService.admin) {
      let isAdmin = await this.checkIfAdmin();
      if (isAdmin) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.authService.startAuthentication();
    return false;
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> { //boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  async checkIfAdmin(): Promise<boolean> {
    //let result = this.authService.getUserRole().subscribe(res => {
    return this.commonService.userRole == this.commonService.admin;
    // });

    //return true;
  }

  async checkIsUserEnabled(): Promise<boolean> {
    let result = this.usersService.checkIsUserEnabled().toPromise();
    return result;
  }

  private insertTempDiv(): void {
    $("<cf id='completed-plans' class='layout'></cf>").insertAfter("router-outlet");
  }

  private removeTempDiv(): void {
    $("#completed-plans").remove();
  }
  ngOnInit() {
  }
}

﻿import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';

@Component({
  selector: 'div.layout cf [id=register]',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
  commonService: CommonService;

  constructor(commonService: CommonService) {
    this.commonService = commonService;
  }

  ngOnInit() {
   // this.commonService.remHeader();
   // this.commonService.showHeader();
  }
}

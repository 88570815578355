import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../common/services/auth.service'
import { CodeNode } from 'source-list-map';
import { CommonService } from '../common.service';

@Component({
  selector: 'div.layout cf [id=login]',
  templateUrl: './auth-callback.component.html', 
  styleUrls: ['../assets/css/style.css', '../assets/img/svgs/svgs.css']
})

export class AuthCallbackComponent implements OnInit {
  client_request_id: any;
  commonService: CommonService;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, commonService: CommonService) { 
    this.commonService = commonService;
        
  }
  async ngOnInit() {
    
    var token_id = this.route.snapshot.fragment.split('&')[0].split('=')[1];
    console.log(token_id);
    await this.authService.completeAuthentication(token_id);    
  }
}

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
@Injectable()

export class ClientInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = {
            'X-Frame-Options': 'SAMEORIGIN'
        };

        let token = localStorage.getItem("azureToken");
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let clientId = localStorage.getItem('clientId');
        if (clientId) {
            // console.log("ClientInterceptor sending request to ", request.url, "for clientId", clientId);
            headers["X-SUB-ClientID"] = clientId;
        }

        request = request.clone({
            setHeaders: headers
        });
        return next.handle(request);
    }
}
import { Injectable } from '@angular/core';

@Injectable()
export class FBTConstants {

    // Local DEV (Azure AD)
    // public static readonly base_url: string = 'http://localhost:50637/'; // Web API URL

    // public static readonly client_id: string = '4e8a94c0-33ab-4f42-ba0a-6e452a683003';
    // public static readonly redirect_uri: string = 'http://localhost:4200/auth-callback';
    // public static readonly post_logout_redirect_uri: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce/oauth2/v2.0/logout';
    // public static readonly silent_redirect_uri: string = 'http://localhost:4200/silent-refresh.html';
    // public static readonly authority: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce';
    // public static readonly issuer: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce/v2.0';
    // public static readonly authorization_endpoint: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce/oauth2/v2.0/authorize';
    // public static readonly userinfo_endpoint: string = 'https://graph.microsoft.com/oidc/userinfo';
    // public static readonly end_session_endpoint: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce/oauth2/v2.0/logout';
    // public static readonly jwks_uri: string = 'https://login.microsoftonline.com/a15bd49e-2167-467b-b2ac-78dcf57ce5ce/discovery/v2.0/keys';
    // public static readonly signingKeys: string = ''; //'https://dttstsstage.deloitteresources.com/adfs/discovery/keys';
    // public static readonly resource: string = null;//'http://localhost:4200';
    // public static readonly response_type: string = 'id_token token';
    // public static readonly scope: string = 'openid profile';


    //UAT
    //   public static readonly base_url: string = 'https://localhost:44378/'; //'https://localhost:50638/';  //'https://api.fbtdeclarationmanageruat.deloitte.com.au/';// 'https://api.fbtdeclarationmanageruat.deloitte.com.au/'; //  'https://api.fbtdeclarationmanagerdev.au.deloitte.com/';
    //   public static readonly client_id: string = '5d3286dc-ce40-4fb8-8e32-255348cc2851';
    //   public static readonly redirect_uri: string = 'https://fbtdeclarationmanageruat.deloitte.com.au/auth-callback';
    //   public static readonly post_logout_redirect_uri: string = 'https://dttstsstage.deloitteresources.com/adfs/oauth2/logout';
    //   public static readonly silent_redirect_uri: string = 'https://fbtdeclarationmanageruat.deloitte.com.au/silent-refresh.html';
    //   public static readonly authority: string = 'https://dttstsstage.deloitteresources.com/adfs/oauth2/authorize/';
    //   public static readonly issuer: string = 'https://dttstsstage.deloitteresources.com/adfs';
    //   public static readonly authorization_endpoint: string = 'https://dttstsstage.deloitteresources.com/adfs/oauth2/authorize/';
    //   public static readonly userinfo_endpoint: string = 'https://dttstsstage.deloitteresources.com/adfs/userinfo';
    //   public static readonly end_session_endpoint: string = 'https://dttstsstage.deloitteresources.com/adfs/oauth2/logout';
    //   public static readonly jwks_uri: string = 'https://dttstsstage.deloitteresources.com/adfs/discovery/keys';
    //   public static readonly signingKeys: string = 'https://dttstsstage.deloitteresources.com/adfs/discovery/keys';
    //   public static readonly resource: string = 'https://fbtdeclarationmanageruat.deloitte.com.au';
    // public static readonly response_type: string = 'id_token';
    // public static readonly scope: string = 'openid profile api1';


    //PROD
    //public static readonly base_url: string = 'https://api.fbtdeclarationmanager.deloitte.com.au/';
    //public static readonly client_id: string = 'ae504526-a787-4257-8c3d-0a4f818b366e';
    //public static readonly redirect_uri: string = 'https://FBTdeclarationmanager.deloitte.com.au/auth-callback';
    //public static readonly post_logout_redirect_uri: string = 'https://dttsts.deloitteresources.com/adfs/oauth2/logout';
    //public static readonly silent_redirect_uri: string = 'https://FBTdeclarationmanageru.deloitte.com.au/silent-refresh.html';
    //public static readonly authority: string = 'https://dttsts.deloitteresources.com/adfs/oauth2/authorize/';
    //public static readonly issuer: string = 'https://dttsts.deloitteresources.com/adfs';
    //public static readonly authorization_endpoint: string = 'https://dttsts.deloitteresources.com/adfs/oauth2/authorize/';
    //public static readonly userinfo_endpoint: string = 'https://dttsts.deloitteresources.com/adfs/userinfo';
    //public static readonly end_session_endpoint: string = 'https://dttsts.deloitteresources.com/adfs/oauth2/logout';
    //public static readonly jwks_uri: string = 'https://dttsts.deloitteresources.com/adfs/discovery/keys';
    //public static readonly signingKeys: string = 'https://dttsts.deloitteresources.com/adfs/discovery/keys';
    //public static readonly resource: string = 'https://FBTdeclarationmanageruat.deloitte.com.au';
    // public static readonly response_type: string = 'id_token';
    // public static readonly scope: string = 'openid profile api1';


    //helper constants
    public static readonly role_admin: string = "ADMIN";
    public static readonly role_user: string = "USER";
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material';
import { finalize } from 'rxjs/operators';

import { ClientService } from '../common/services/client-service';
import { AppConfigService } from '../app-config/app-config.service';
import { ClientSettings } from '../common/models/client-settings';
import { CommonService } from '../common.service';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit, OnDestroy {

    colors = ["blue", "red", "green", "yellow", "orange"];
    currencies = ["$", "€", "£"];

    loading: boolean;
    settings: ClientSettings = new ClientSettings();

    navigationSubscription: any;
    
    constructor(
        public router: Router,
        public commonService: CommonService,
        public clientService: ClientService,
        public appConfig: AppConfigService
        ) {
        
        // If it is a NavigationEnd event re-initalise the component
        this.navigationSubscription = router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.loadClientAppSettings();
            }
        });
    }

    ngOnInit() {
        this.loadClientAppSettings();
    }

    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    loadClientAppSettings() {
        this.loading = true;
        this.clientService.getClientSettings()
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.settings = result;
                },
                (error) => {
                    console.log('There was an error loading client settings', error);
                }
            );
    }

    saveSettings() {
        this.loading = true;

        this.clientService.saveClientSettings(this.settings)
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                (result) => {
                    this.commonService.setWebPageTitle();
                    this.commonService.openSnackBarMessage("Client settings saved OK");
                },
                (error) => {
                    this.commonService.openSnackBarError("There was an error saving client settings");
                    console.log('There was an error saving client settings', error);
                }
            );
    }

    getSubmittedData() {
        this.loading = true;

        this.clientService.getSubmittedData()
            .pipe(finalize(() => this.loading = false))
            .subscribe(
                () => {
                    alert('Declaration data submitted ok');
                },
                (error) => {
                    console.log('There was an error submitting declaration data', error);
                }
            );
    }

    onDateToggle(ob: MatSlideToggleChange) {
        if (this.settings) {
            this.settings.showDeclarationDates = ob.checked;
        }
    }
}

